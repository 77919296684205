import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Masonry from 'react-masonry-component';
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/pageStyles/_Projects.scss"

import AlleIcon from "../assets/svg/icons/filter_all.svg"
import WebseiteIcon from "../assets/svg/icons/filter_website.svg"
import PlattformIcon from "../assets/svg/icons/filter_plattform.svg"

var filterStatus = "Alle";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.handleCatagory = this.handleCatagory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.handleCatagory);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keydown', this.handleCatagory);
  }

  handleScroll() {
    var height = 57;
    var projectFilter = document.getElementById("projectFilter");
    if (window.pageYOffset < height) {
      projectFilter.classList.remove("side");
    } else if (window.pageYOffset >= height) {
      projectFilter.classList.add("side");
    }
  }

  handleCatagory(event) {
    if (event.type === 'keydown') {
      var key = (event.which !== null ? event.which : event.keyCode);
      switch (key) {
        case 49:
        case 65:
          filterStatus = 0;
          break;
        case 50:
        case 87:
          filterStatus = 1;
          break;
        case 51:
        case 80:
          filterStatus = 2;
          break;
        default:
          filterStatus = -1;
          break;
      }
      if (filterStatus === -1) return;
      filterStatus = document.getElementById("projectFilter").childNodes[0].childNodes[filterStatus].dataset.val;
    } else if (typeof event.currentTarget.dataset.val !== "undefined") {
      filterStatus = event.currentTarget.dataset.val;
    } else {
      filterStatus = 0;
    }
    this.setState({ value: filterStatus });
    var bar = document.getElementById("projectFilter").childNodes;
    for (var i = 0; i < bar.length; i++) {
      var tab = bar[i].childNodes;
      for (var j = 0; j < bar.length; j++) {
        if (tab[i].dataset.val === filterStatus) {
          tab[i].classList.add("active");
        }
        else if (tab[i].classList.contains("active")) {
          tab[i].removeAttribute("class");
        }
      }
    }
  }

  //fluid(maxWidth: 500, maxHeight: 500, cropFocus: NORTH, webpQuality: 90 , toFormat: WEBP)
  //order can be either ASC or DESC 
  render() {
    return (
      <StaticQuery
        query={graphql`
        {
          allStrapiProjects(sort: {fields: publication_date, order: DESC}) {
            edges {
              node {
                id
                title
                link
                description_short
                description_details
                category
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      height: 500
                      transformOptions: {cropFocus: NORTH}
                      placeholder: BLURRED
                      formats: [WEBP,PNG]
                    )
                  }
                }
              }
            }
          }
        }
      `}
        render={data => {

          return (
            <section className="projects">
              <div id="projectFilter" className="text_big">
                <div className="projectFilterTop">
                  <div className={filterStatus === "Alle" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Alle">
                    <span>Alle</span>
                  </div>
                  <div className={filterStatus === "Webseite" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Webseite">
                    <span>Webseiten</span>
                  </div>
                  <div className={filterStatus === "Plattform" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Plattform">
                    <span>Plattformen</span>
                  </div>
                </div>
                <div className="projectFilterLeft">
                  <div className={filterStatus === "Alle" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Alle">
                    <span>Alle</span>
                    <aside className="icon"><AlleIcon /></aside>
                  </div>
                  <div className={filterStatus === "Webseite" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Webseite">
                    <span>Webseiten</span>
                    <aside className="icon"><WebseiteIcon /></aside>
                  </div>
                  <div className={filterStatus === "Plattform" ? 'active' : ''} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory} data-val="Plattform">
                    <span>Plattformen</span>
                    <aside className="icon"><PlattformIcon /></aside>
                  </div>
                </div>
              </div>
              <Masonry id="allProjects" className="showcase" onScroll={this.handleScroll}>
                {data.allStrapiProjects.edges.map(({ node: project }) => (
                  (project.image.childImageSharp !== null ?
                    (filterStatus === "Alle" || project.category === filterStatus ?
                      <div key={project.id} className="masonry">
                        <figure id={project.id} className="card">
                          {/* If has detail Page
                          <Link className="card_image" to={`/projekte/${project.link}`} state={{ filterStatus }}>
                            <Img fluid={project.image.childImageSharp.fluid}/>
                          </Link> */}
                          <a href={`https://${project.link}`} className="card_image" target="_blank" rel="noreferrer" aria-label={project.title}>
                            <GatsbyImage alt={project.title} image={project.image.childImageSharp.gatsbyImageData}/>
                          </a>
                          <figcaption>
                            <a href={`https://${project.link}`} target="_blank" rel="noreferrer">{project.title}</a>
                            <div className="tags">
                              <span data-val={project.category} role="button" tabIndex="0" onClick={this.handleCatagory} onKeyDown={this.handleCatagory}>
                                {project.category}
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                    : "")
                  : console.error("Image missing: " + project.id))
                ))}
              </Masonry>
            </section>
          )
        }}
      />
    )
  }
}

const IndexPage = () => (
  <Layout>
    <Seo title="Index" />
    <Gallery />
  </Layout>
)

export default IndexPage